<script setup>
import NavBar from '@/components/NavBar.vue';
import BurgerSlide from '@/components/BurgerSlide.vue';
import axios from 'axios';
</script>

<template>
    <header>
        <!-- Navigation bar -->
        <div id="headerer" style="display: block;">
            <NavBar></NavBar>
        </div>
    </header>
    <main>
        <!-- Burger menu -->
        <div id="slider" style="display: none;">
            <BurgerSlide></BurgerSlide>
        </div>
        <div class="title">
            <img src="backwide.webp" alt="background" style="width: 100%; height: 40.98%;">
            <div class="center"><h1>Kontakt</h1></div>
        </div>
        <div class="mainView">
            <!-- Info about the company -->
            <div class="companyInfo">
                <div class="companyContact">
                    Galina Kovalevskaja<br>
                    Telefon: <a href="tel:+3725140807">+372 514 0807</a><br>
                    E-post: <a href="mailto:galarmp@gmail.com">galarmp@gmail.com</a><br>
                    LinkedIn: <a href="https://www.linkedin.com/company/galarmp/">linkedin.com/company/galarmp</a><br><br>
                    Gala RMP OÜ<br>
                    Reg-nr: 1463 7267<br>
                    Aadress: Sipasoo tee 15-4, Habaja, Kose vald, 75002, Harjumaa
                </div>
            </div>
        </div>
    
    
     <div class="mainView">
        <div class="form">
            <h2>Võta ühendust</h2>
            <form id="messageForm">
                <div class="info">
                    <div class="labels">
                        <div class="singleLabel">
                            <label for="email">E-post:</label><br>
                        </div>
                        <div class="singleLabel">
                            <label for="phone">Telefon:</label>
                        </div>
                    </div>
                    <div class="inputs">
                        <input type="email" id="email" name="email" placeholder="näide@mail.ee"><br>
                        <input type="phone" id="phone" name="phone" placeholder="+372 1234 5678"><br>
                    </div>
                </div>
                <label for="message">Sõnum:</label><br>
                <textarea id="message" name="message" form="messageForm" class="message"></textarea>
                <div class="buttons">
                    <input type="button" value="Saada" v-on:click="sendMail()" class="submitButton">
                </div>
            </form>
        </div>
    </div>
</main>
</template>

<script>
export default {
    
  data() {
    return {
      windowWidth: window.innerWidth,
      textSize: '4rem',
      textSize2: '2rem',
    };
  },
  
  methods: {
    async sendMail() {
        try {
            const mail = document.getElementById("email").value;
            const phone = document.getElementById("phone").value;
            const content = document.getElementById("message").value;
            const kast = document.getElementsByClassName("submitButton")[0];
            kast.value = "Kiri saatmisel"
            console.log(mail, phone, content)
            const response = await axios.put('https://api.galarmp.ee/api/mail/', {
                mail: mail,
                phone: phone,
                content: content
            })

            console.log(response.data)
            if (response.data.accepted.length > 0) {
                kast.value = "Kiri saadetud"
                kast.style.background='#d1ffbd'
                await new Promise(resolve => setTimeout(resolve, 5000));
                kast.value = "Saada kiri"
                kast.style.background='#ffffff'
            } else {
                kast.value = "Tekkis viga"
                kast.style.background='#ff474c'
                await new Promise(resolve => setTimeout(resolve, 5000));
                kast.value = "Saada kiri"
                kast.style.background='#ffffff'
            }
        } catch (error) {
            console.log(error);
        }
    },
    // What happens when the window is < 1024px
    onResize() {
      if (window.innerWidth < 1024) {
        let element1 = document.getElementById('slider');
        let element2 = document.getElementById('headerer');
        element1.style.display = "block";
        element2.style.display = "none";
        this.textSize = (window.innerWidth/250).toString() + 'rem';
        this.textSize2 = (window.innerWidth/300).toString() + 'rem';
      } else {
        let element2 = document.getElementById('slider');
        let element1 = document.getElementById('headerer');
        element1.style.display = "block";
        element2.style.display = "none";
        this.textSize = '4rem';
      }
    }
  },
  
  mounted() {
    this.onResize()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },

  beforeUnmount() { 
    window.removeEventListener('resize', this.onResize); 
  }
}
</script>

<style scoped>
header {
    position: sticky;
    top: 0;
    z-index: 1000;
}

h1 {
    font-weight: 500;
    font-size: v-bind('textSize');
    position: relative;
    color: #0d1b2a;
}

a {
    background: none;
    color: #32455A;
}

label {
    padding-right: 1em;
    font-size: 1.2em;
}

h2 {
    margin-bottom: 1em;
}

input {
    border-radius: 1em;
    width: 20em;
    height: 2em;
    padding: 5%;
    margin-bottom: 1em;
    margin-top: 0.1em;
}

textarea {
    padding: 1em;
} 

.mainView {
  max-width: 75%;
  margin: auto;
}

.title {
  position: relative;
  text-align: center;
  padding-bottom: 4em;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.companyContact {
    margin-bottom: 1em;
}

.info {
    display: flex;
}

.singleLabel {
    margin-bottom: 1em;
}

.message {
    border-radius: 0.7em;
    min-width: 53em;
    min-height: 20em;
    max-width: 100%;
    max-height: 50em;
} 

.submitButton {
    padding: 0%;
    width: 10em;
    height: 3em;
    border-radius: 2em;
}

.submitButton:hover {
    cursor: pointer;
    background-color: grey;
} 

#message {
    width: 80%;
    height: 30em;
    border-radius: 1em;
} 

</style>